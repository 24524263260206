<template>
  <div class="sg-container">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>{{ title }}</span>
        <div style="float: right" class="sg-flex-center">
          <el-checkbox
            label="全选"
            border
            size="mini"
            v-model="allChecked"
          ></el-checkbox>
          <el-button
            @click="deleteMulti"
            size="mini"
            class="sg-margin-left"
            type="danger"
            >删除</el-button
          >
          <el-button
            @click="openUpload"
            size="mini"
            class="sg-margin-left"
            type="primary"
            >上传</el-button
          >
        </div>
      </div>
      <el-row justify="start" type="flex" class="sg-flex-wrap">
        <el-col
          :sm="{ span: 4 }"
          v-for="(item, index) in list"
          :key="index"
          class="sg-pad"
        >
          <el-card @click.native="switchCheck(index)">
            <div class="sg-img-wrapper sg-relative">
              <div
                class="
                  sg-action-box
                  sg-absolute
                  sg-w100
                  sg-h100
                  sg-flex-center
                  sg-trans-gray
                  sg-zindex-100
                "
              >
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  @click.stop="edit(item)"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click.stop="doDelete(item.id)"
                ></el-button>
              </div>
              <img :src="item.url" class="sg-w100 sg-upload-img" />
            </div>
            <div class="sg-space-between sg-align-center">
              <div>
                <div class="sg-pad-tb-sm">{{ item.name }}</div>
                <div class="sg-font-sm sg-gray">
                  {{ item.created_at | formatDate }}
                </div>
              </div>
              <el-checkbox
                v-model="item.checked"
                @change="switchCheck(index)"
              ></el-checkbox>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="sg-pagination">
        <el-pagination
          @current-change="pageChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      title="编辑图片"
      :visible="showDialog"
      width="90%"
      height="90%"
      top="5vh"
      append-to-body
      @close="switchShowDialog(false)"
      class="sg-hide-box"
    >
      <EditImage
        :src="editImageUrl"
        @close="switchShowEdit(false)"
      ></EditImage>
    </el-dialog>
    <input
      type="file"
      name="file"
      class="sg-hide"
      ref="uploadInput"
      multiple="true"
      @change="change($event)"
    />
  </div>
</template>

<script>
// @ is an alias to /src
// import { mapState } from "vuex"
import EditImage from "@/components/Share/EditImage.vue";

export default {
  name: "Upload",
  components: {
    EditImage
  },
  data() {
    return {
      title: "上传管理",
      list: [],
      total: 0,
      pageSize: 15,
      current: 1,
      showDialog: false,
      pre_uri: "upload/",
      searchItem: {
        name: "",
      },
      rules: {},
      editImageUrl: ''
    };
  },
  computed: {
    allChecked: {
      get: function () {
        return this.list.findIndex((item) => !item.checked) == -1;
      },
      set: function (val) {
        this.checkAll(val);
      },
    },
    totalPages: () => {
      if (this.total) return Math.ceil(this.total / this.pageSize);
      else return 1;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    edit(item) {
      this.editImageUrl = item.url
      this.switchShowDialog(true)
    },
    change(e) {
      var that = this;
      that.$store.commit("updateLoading", true);
      this.$util.uploadFiles(that, e, function (res) {
        that.$store.commit("updateLoading", false);
        that.$refs.uploadInput.value = "";
        if (res.code == 0) {
          that.getList();
        }
      });
    },
    openUpload() {
      this.$refs.uploadInput.click();
    },
    deleteMulti() {
      var checkedList = this.list.filter((item) => item.checked);
      if (checkedList.length <= 0) {
        this.$message({
          type: "error",
          message: "请先选中文件",
        });
        return false;
      }
      var ids = checkedList.map((item) => item.id);
      this.doDelete(ids);
    },
    checkAll(allChecked) {
      var list = this.list;
      for (var i = 0; i < list.length; ++i) {
        this.$set(this.list[i], "checked", allChecked);
      }
    },
    switchCheck(index) {
      var item = this.list[index];
      item.checked = item.checked ? false : true;
      this.$set(this.list, index, item);
    },
    getList() {
      var that = this;
      this.$store.commit("updateLoading", true);
      this.$http
        .post(
          this.pre_uri + "get",
          Object.assign(
            {
              page: this.current,
            },
            this.searchItem
          )
        )
        .then((res) => {
          this.$store.commit("updateLoading", false);
          if (res.code == 0) {
            that.$util.parsePagiList(that, res.data);
          }
        });
    },
    pageChange(current) {
      this.current = current;
      this.getList();
    },
    doDelete(ids) {
      var that = this;
      ids = that.$util.isArray(ids) ? ids : [ids];
      if (ids.length <= 0) {
        this.$message({
          type: "error",
          message: "请先选择文件",
        });
        return false;
      }
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.$util.doAction(
            that,
            that.pre_uri + "deleteAll",
            {
              ids: ids,
            },
            true,
            function (res) {
              if (res.code == 0) {
                that.$message({
                  type: "success",
                  message: "操作成功",
                });
                that.getList();
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    switchShowDialog(show) {
      this.showDialog = show;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/global.scss";
.sg-upload-img {
  height: 150px;
  object-fit: cover;
}
.sg-img-wrapper {
  overflow: hidden;
  .sg-action-box {
    opacity: 0;
    transition: all 0.4s;
  }
  img {
    transition: all 0.4s;
  }
  &:hover {
    img {
      transform: scale(1.2);
    }
    .sg-action-box {
      opacity: 1;
    }
  }
}
</style>